<template>
	<div>
		<div>
			Search Route List
			<div>
				<b-button class="is-success">Search without time limited</b-button>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'SearchRouteList',
	setup() {},
}
</script>

<style lang="scss" scoped></style>
