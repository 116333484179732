<template>
	<LayoutDefaultNew>
		CreateNewRouteList
		<SubNavbar page="Delivery" />

		<div>
			<GetCreateRouteListData />
		</div>

		<div>
			<SearchRouteList />
		</div>

		<div>
			<CreateRouteListHeaders />
		</div>
	</LayoutDefaultNew>
</template>

<script>
import SubNavbar from '@/_srcv2/components/_shared/sub-navbar/SubNavbar'
import GetCreateRouteListData from '@/_srcv2/pages/deliveries-route-lists/create-route/components/GetCreateRouteListData.vue'
import CreateRouteListHeaders from '@/_srcv2/pages/deliveries-route-lists/create-route/components/CreateRouteListHeaders.vue'
import SearchRouteList from '@/_srcv2/pages/deliveries-route-lists/create-route/components/SearchRouteList.vue'
export default {
	name: 'CreateNewRouteList',
	components: {
		SubNavbar,
		SearchRouteList,
		GetCreateRouteListData,
		CreateRouteListHeaders,
	},
	setup() {},
}
</script>

<style lang="scss" scoped></style>
